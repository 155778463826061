/**
 * @generated SignedSource<<76f1699d9ecbfb0adeeb5215f1253aa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedFavoritesProvider_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FolderWrapperWrapper_viewer" | "HeartWrapper_viewer">;
  readonly " $fragmentType": "SharedFavoritesProvider_viewer";
};
export type SharedFavoritesProvider_viewer$key = {
  readonly " $data"?: SharedFavoritesProvider_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedFavoritesProvider_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "selectedItemIds",
  "variableName": "selectedItemIds"
},
v1 = {
  "kind": "Variable",
  "name": "userIds",
  "variableName": "userIds"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "loadPortfolioData"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "selectedItemIds"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "shouldFillInFolder"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "userIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedFavoritesProvider_viewer",
  "selections": [
    {
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "shouldFillInFolder",
          "variableName": "shouldFillInFolder"
        },
        (v1/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "FolderWrapperWrapper_viewer"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "loadPortfolioData",
          "variableName": "loadPortfolioData"
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "HeartWrapper_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "62a959fb412aafd29592976bcb1c4bc1";

export default node;
