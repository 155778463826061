/**
 * @generated SignedSource<<204e73c08f49c37e758350766289c3c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeartWrapper_viewer$data = {
  readonly heartItemMatches?: ReadonlyArray<{
    readonly item: {
      readonly serviceId: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"Heart_heartPortfolioItems">;
  } | null> | null;
  readonly itemRead?: ReadonlyArray<{
    readonly serviceId?: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"Heart_item">;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"Heart_viewer">;
  readonly " $fragmentType": "HeartWrapper_viewer";
};
export type HeartWrapper_viewer$key = {
  readonly " $data"?: HeartWrapper_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeartWrapper_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "itemIds",
  "variableName": "selectedItemIds"
},
v1 = {
  "kind": "Variable",
  "name": "userIds",
  "variableName": "userIds"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "loadPortfolioData"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "selectedItemIds"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showHeartCount"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "userIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeartWrapper_viewer",
  "selections": [
    {
      "condition": "loadPortfolioData",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "heartItemMatches",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "portfolioTypes",
              "value": [
                "HEART"
              ]
            },
            (v1/*: any*/)
          ],
          "concreteType": "PortfolioItemV2",
          "kind": "LinkedField",
          "name": "portfolioItemMatches",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Heart_heartPortfolioItems"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "showHeartCount",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            (v0/*: any*/)
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "itemRead",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Heart_item"
                }
              ],
              "type": "Item",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "loadPortfolioData",
          "variableName": "loadPortfolioData"
        },
        (v1/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "Heart_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "a32683e81d16903b555ea3e5ac90433c";

export default node;
